import React from 'react';
import './mobile.css';

const AboutUs = () => {
    const cards = [
        {
            imgSrc: '/images/huumans3-1024x820.png',
            header: 'Transform Customer-Backend Connectivity for a New Business Era',
            description: 'Imagine a world where your customers can effortlessly connect with your backend systems anytime, anywhere. With agentBo, that vision becomes a reality. We have harnessed the power of cutting-edge technology to create a gateway to direct engagement, eliminating barriers and ushering in a new era of customer-business interaction.'
        },
        {
            imgSrc: '/images/depositphotos_189422448-stock-illustration-simple-rocket-vector-icon-removebg-preview.png',
            header: 'Turbo charge your B2C customer engagement',
            description: "agentBo, a key component of Stencil Technologies' applications, embodies our dedication to innovation. Our experts have skillfully crafted this platform, fusing user-friendly communication channels with backend system efficiency. The outcome? An empowering, transformative tool that gives your business unparalleled control over customer engagement."
        },
        {

            imgSrc: '/images/standing-9.png',
            header: 'Elevate Your Customer Interactions with agentBo',
            description: "Join the ranks of businesses that have already elevated their interactions to the next level with agentBo. Embrace the future of customer engagement, where connections are made effortlessly, engagement is genuine, and results are tangible."
        },
      
    ];

    return (
        <div>
            <section>
                <div style={styles.herocontainer}>
                <div style={styles.overlay}></div>
                    <h1 style={styles.herocontainerh1}>About Us</h1>
                </div>
            </section>
            
            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                        Welcome to agentBo, your ultimate solution for empowering your business with an unrivaled 24/7 digital omni-channel presence. With seamless integration across popular user channels like WhatsApp, Instagram, Facebook, and more, agentBo, a flagship product of Stencil Technologies' Communications-As-A-Service suite, redefines B2C interactions.    </p>
                    </div>
                </div>

            </section>
            <section>
                <div className="cardcontainer" style={styles.cardSection}>
                    {cards.map((card, index) => (
                        <div className="card" style={styles.card} key={index}>
                            <img style={styles.cardImg}src={card.imgSrc} alt={`card-${index}`} />
                            <h1 style={styles.cardheader}> {card.header} </h1>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>


        </div>
    );
};


const styles = {

    herocontainer: {
        backgroundImage: 'url("images/bba-usurm-2019-900x400-min.jpeg")',
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
       
    },
    
     herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4.9em',
        fontFamily: 'jaldi',
    },

      overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 119, 209, 0.5)',  // Semi-transparent overlay
        zIndex: 1,  // Ensure this is below the h1
    },

   

    descriptionSection:{
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft:'10%',
        marginRight:'10%',
        zIndex: 3,
    
    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },
    cardSection: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        margin: '40px 0',
        zIndex: 3,
      },
      
      card: {
        width: '22%',
        backgroundColor: '#fff',
        padding: '20px',
        textAlign: 'center',
        borderRadius: '8px',

        border: 'solid 1px #cccccc'
      },

      cardheader:{
        color:'#0069C8',
      },
      
      cardImg: {
        width: '100%',
        marginBottom: '10px',
      },
      
      cardP: {
        fontSize: '14px',
        color: '#333',
      },

};

export default AboutUs;
