import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/hero';
import About from './Pages/About-Us';
import Layout from './Components/Layout';
import Education from './Pages/Education';
import Insurance from './Pages/Insurance';
import HealthCare from './Pages/HealthCare';
import Ecommerce from './Pages/Ecommerce';
import ContactUS from './Pages/ContactUs';
import Chatbots from './Pages/Chatbots';
import Livechat from './Pages/Livechart';
import WhatsappFeatures from './Pages/WhatsappFeatures';
import SheduleADemo from './Pages/SheduleADemo';
import Privacypolicy from './Pages/Privacypolicy';
import Termsandconditions from './Pages/Termsandconditions';


const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/education" element={<Education />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/healthcare" element={<HealthCare />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/chatbots" element={<Chatbots />} />
          <Route path="/livechat" element={<Livechat />} />
          <Route path="/whatsapp-features" element={<WhatsappFeatures />} />
          <Route path="/Shedule-a-demo" element={<SheduleADemo />} />
          <Route path="/Privacy-policy" element={<Privacypolicy />} />
          <Route path="/Terms-and-conditions" element={<Termsandconditions />} />

        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
