import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './ResponsiveMenu.css';

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = (menu) => {
        setShowDropdown(showDropdown === menu ? null : menu);
    };

    return (
        <nav className="navbar">
            <Link to="/" ><img className="navbar-brand"
                src="/images/agentBo-white-background-8.png"
                alt="Logo-image"
            /></Link>


            <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                <li><Link to="/" >Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li
                    onMouseEnter={() => toggleDropdown('Features')}
                    onMouseLeave={() => toggleDropdown(null)}
                >
                    <a href="#Features">Features</a>
                    {showDropdown === 'Features' && (
                        <ul className="dropdown">
                            <li><Link to="/chatbots" >Chatbots</Link></li>
                            <li><Link to="/whatsapp-features" >Whatsapp Features</Link></li>
                            <li><Link to="/livechat" >livechat</Link></li>

                        </ul>
                    )}
                </li>
                <li
                    onMouseEnter={() => toggleDropdown('Industry-Use-cases')}
                    onMouseLeave={() => toggleDropdown(null)}
                >
                    <a href="#Industry-Use-cases">Industry Use-cases </a>
                    {showDropdown === 'Industry-Use-cases' && (
                        <ul className="dropdown">
                            <li><Link to="/education" >Education</Link></li>
                            <li><Link to="/insurance" >Insurance</Link></li>
                            <li><Link to="/healthcare" >HealthCare</Link></li>
                            <li><Link to="/ecommerce" >Ecommerce</Link></li>
                        </ul>
                    )}
                </li>

                <li><Link to="/contact-us" >Contact Us</Link></li>

                <button className='navbutton'>
                    <Link to="/Shedule-a-demo" >Schedule a demo</Link>
                </button>

            </ul>

        </nav>
    );
};

export default Menu;
