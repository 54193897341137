import React from 'react';
import './mobile.css';
import { Link } from 'react-router-dom';


const Ecommerce = () => {
    return (
        <div>
            <section>
                <div style={styles.herocontainer}>
                    <div style={styles.overlay}></div>
                    <h1 style={styles.herocontainerh1}>
                        E-Commerce</h1>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            Transform your e-commerce business with agentBo’s 24/7 digital omni-channel presence. Engage effortlessly with customers on WhatsApp, Instagram, Facebook, and more, ensuring seamless, direct interactions anytime, anywhere.
                        </p>
                    </div>
                </div>

            </section>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/ecommerce 1.jpg"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className='marginleft'>
                            <h1 style={styles.heading}>Boost e-commerce growth and customer satisfaction across multiple channels</h1>
                            <p style={styles.description}>
                                Improve client interactions and seize new chances in the online market and optimise the purchasing process, expedite correspondence, and eventually boost conversions by implementing a smooth, multi-channel strategy that caters to your clients’ requirements regardless of their location.


                            </p>

                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>


                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/ecommerce 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>



                    <div className="textColumn" style={styles.textColumn}>
                        <div style={{ marginRight: '20px', marginLeft: '60px' }}>
                            <h1 style={styles.heading}>Market Your Products</h1>
                            <p style={styles.description}>
                                Maximize sales and satisfy customers through strategic product marketing. Utilize effective messaging across digital channels to attract and convert buyers, ensuring a positive customer experience.
                            </p>
                            <ul style={styles.ul}>
                                <li><p>Use your marketing budget more effectively. </p></li>
                                <li><p>Foster greater customer loyalty and retention. </p></li>
                                <li><p> Streamline communication with your audience. </p></li>
                                <li><p> Boost conversion rates.</p></li>
                            </ul>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/ecommerce 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>

            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/ecommerce 3.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>

                        <div className='marginleft'>
                            <h1 style={styles.heading}>
                                Enhance Customer Support</h1>
                            <p style={styles.description}>
                                Create a chatbot that delivers immediate responses and tailored solutions for product inquiries
                            </p>

                            <ul style={styles.ul}>
                                <li><p>Offer 24/7 support availability. </p></li>
                                <li><p>Respond to customer inquiries instantly. </p></li>
                                <li><p>Reduce wait times with automated responses. </p></li>
                                <li><p>Streamline communication to resolve issues quickly. </p></li>
                            </ul>

                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>


                </div>

            </section>

        </div>
    );
};


const styles = {

    herocontainer: {
        backgroundImage: 'url("images/ecommerce cover.jpg")',
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4rem',
    },

    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 119, 209, 0.5)',  // Semi-transparent overlay
        zIndex: 1,  // Ensure this is below the h1
    },



    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        fontSize: '3rem',
        margin: '0 0 20px',
        color: '#fff',
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#0069C8',
        fontWeight: '500'
    },

    description: {
        fontSize: '16px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    ctaButton: {
        padding: '15px',
        backgroundColor: 'transparent', // Transparent background
        color: '#0069C8', // Text color
        border: '2px solid #0069C8', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
    },
    ctaButtonHover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Light hover effect
    },
    imageColumn: {
        flex: 1,
        textAlign: 'right',
    },
    heroImage: {
        width: '90%',
        borderRadius: '30px',
    },

    waveDivider: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '127%',
        overflow: 'hidden',
        lineHeight: 0,
        zIndex: 0,
    },
    waveSvg: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    contactSection: {
        padding: '20px 10px',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    contactSectionheading: {
        fontSize: '2rem',
        color: '#0069C8',
        fontWeight: '500',
        marginBottom: '30px',
    },
    contactSectioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '1100px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    leftColumn: {
        flex: 1,
        backgroundColor: '#0069C8',
        color: '#fff',
        padding: '40px',
        textAlign: 'left',
    },
    rightColumn: {
        flex: 1,
        padding: '40px',
        textAlign: 'left',

    },
    divider: {
        border: '0',
        borderTop: '2px solid #fff',
        width: '50px',
        margin: '10px 0',
    },
    image: {
        height: 'auto',
        margin: '0 auto'
    },
    ul: {
        color: '#1a1a1a',
        marginBottom:'20px',
    }


};

export default Ecommerce;
