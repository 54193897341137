import React from 'react';
import './mobile.css';
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <div>
            <section style={styles.hero}>
            <div style={styles.waveDivider}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={styles.waveSvg}
                    >
                        {/* First wave */}

                        {/* Second wave */}
                        <path
                            fill="#888"
                            fillOpacity="0.5"
                            d="M0,160L48,149.3C96,139,192,117,288,122.7C384,128,480,160,576,170.7C672,181,768,171,864,149.3C960,128,1056,96,1152,117.3C1248,139,1344,213,1392,240L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                        ></path>

                        {/* Third wave */}
                        <path
                            fill="#ccc"
                            fillOpacity="0.3"
                            d="M0,96L48,85.3C96,75,192,53,288,80C384,107,480,181,576,202.7C672,224,768,192,864,160C960,128,1056,96,1152,112C1248,128,1344,192,1392,213.3L1440,235L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                        ></path>
                        <path
                            fill="#fff"
                            fillOpacity="1"
                            d="M0,224L48,213.3C96,203,192,181,288,170.7C384,160,480,160,576,176C672,192,768,224,864,208C960,192,1056,128,1152,106.7C1248,85,1344,107,1392,117.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                        ></path>
                    </svg>
                </div>
                <div className="mobileimage" >
                    <img
                        src="/images/agentbo intro.png"
                        alt="Intro-image"
                        style={styles.heroImage}
                    />
                </div>
                <div style={styles.herocontainer}>

                    {/* Left column (text) */}
                    <div className="herotextColumn" style={styles.herotextColumn}>
                        <h1 style={styles.heroheading}>Engage  More <br />
                            Customers, Faster!</h1>
                        <p style={styles.herodescription}>
                            Empower your business with a 24/7 digital omni-channel presence across popular user channels including WhatsApp, Instagram, Facebook, and more  through agentBo. Elevate B2C interactions as customers seamlessly connect with your backend systems anytime, anywhere. Your gateway to effortless, direct engagement awaits through agentBo.
                            <br /><br />
                            agentBo is a key product of the Stencil Technologies' Communications-As-A-Service suite of applications.
                        </p>

                        <h6 style={styles.smallheading}>Fast. Efficient. Secure.</h6>
                        <button style={styles.ctaButton2}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                    </div>

                    {/* Right column (image) */}
                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/agentbo intro.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>
               
            </section>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/experience now.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft">
                            <h1 style={styles.heading}>EXPERIENCE NOW!</h1>
                            <p style={styles.description}>
                                Generate leads, increase conversions, and improve response time with chatbot and live chat, across multiple B2C channels.
                            </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>
                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="/images/One-View-Inbox-1024x1021.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>
            <section style={styles.section2}>

                <h1 style={{
                    fontSize: '2rem',
                    marginTop: '60px',
                    color: '#0069C8',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>How can agentBo help you?</h1>

                <div className="container" style={styles.container}>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright" >
                            <h1 style={styles.heading}>Every conversation stitched to one view</h1>
                            <p style={styles.description}>
                                Keeping up with customers coming across multiple touch-points can be a challenge. Simplify omnichannel interactions with the OneView Inbox
                            </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/One-View-Inbox-1024x1021.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                </div>

            </section>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/Group-137-3.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft" >
                            <h1 style={styles.heading}>A better, faster, stronger way to connect with customers</h1>
                            <p style={styles.description}>
                                Experience streamlined communication with our free AI chatbot, providing 24/7 lead generation, support optimization, and personalized conversations  </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>
                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="/images/Group-142.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright" >
                            <h1 style={styles.heading}>Everything you need, under one fully integrated platform</h1>
                            <p style={styles.description}>
                                Balance automation with the human touch. Set up quick replies, close sales, and collaborate with customers in real-time with Live Chat
                            </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/Group-142.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>

                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/time solution.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginleft" >
                            <h1 style={styles.heading}>A solution that saves time</h1>
                            <p style={styles.description}>
                                That’s what your customers want. Keep conversations flowing as your AI chatbot platform answers complex queries within seconds with DocuSense   </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>
                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                <img
                    src="/images/Group-143.png"
                    alt="Intro-image"
                    style={styles.heroImage}
                />
            </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>



                    <div className="textColumn" style={styles.textColumn}>
                        <div className="marginright">
                            <h1 style={styles.heading}>Get more value from your favourite tools</h1>
                            <p style={styles.description}>
                                Seamlessly integrate AgentBO with third-party applications and CRMs you already use for marketing, ticketing, analytics, and growth </p>
                            <button style={styles.ctaButton1}><Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>Learn more</Link></button>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none', color: 'inherit' }}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/Group-143.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>
            <section style={styles.contactSection}>
                <h2 style={styles.contactSectionheading}>Contact Us</h2>

                <div style={styles.contactSectioncontainer}>
                    {/* Address Column */}
                    <div style={styles.leftColumn}>
                        <h3>Address</h3>
                        <hr style={styles.divider} />
                        <br/>
                        <br/>
                        <p style={styles.contactcolor} >
                            Plot 54368, iTowers Unit 4A
                        </p>
                        <br/>
                        <p style={styles.contactcolor}>
                            +267 3165466
                      
                        </p>  <br/>
                        <p style={styles.contactcolor}>
                            info@agentbo.co.bw
                        </p>
                    </div>

                    {/* Opening Hours Column */}
                    <div style={styles.rightColumn}>
                        <h3>Opening Hours</h3>
                        <hr style={styles.divider} />
                        <div style={styles.imgrightColumn}>
                        <img
                            src="/images/image001-2-qazl4k6qtcksnzbhia9ogrnz9fsf1xufl9gv0a8yrs.png"
                            alt="24/7"
                            style={styles.image}
                        />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};


const styles = {
    hero: {
        position: 'relative',
        background: 'linear-gradient(180deg, #0069C8, #000000)',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '200px',
        textAlign: 'left',
        overflow: 'hidden',
    },
    herocontainer: {
        
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '50px',
        position: 'relative',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        margin: '0 0 20px',
        color: '#fff',
        fontSize: '4rem'
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#0069C8',
        fontWeight: '500'
    },

    description: {
        fontSize: '16px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    ctaButton2: {
        padding: '15px',
        backgroundColor: '#0069C8', // Transparent background
        color: '#fff', // Text color
        border: '2px solid #fff', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        zIndex: 1,
        marginTop: '20px',
    },
    ctaButton2Hover: {
        padding: '15px',
        backgroundColor: '0069C8', // Transparent background
        color: '#fff', // Text color
        border: '2px solid #fff', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease',
    },

    ctaButton1: {
        padding: '15px',
        backgroundColor: '#0069C8', // Transparent background
        color: '#fff', // Text color
        border: '2px solid #0069C8', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
        marginRight: '20px',
        marginBottom: '20px'
    },
    ctaButton1Hover: {
        transform: 'scale(0.95)',
        backgroundColor: 'trasparent', // Light hover effect
        border: '2px solid #0069C8', // Optional border to outline the button
    },

    ctaButton: {
        padding: '15px',
        backgroundColor: 'transparent', // Transparent background
        color: '#0069C8', // Text color
        border: '2px solid #0069C8', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
    },
    ctaButtonHover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Light hover effect
    },
    imageColumn: {
        flex: 1,
        textAlign: 'right',
    },
    heroImage: {
        width: '90%',

    },

    contactcolor:{
        color:'#fff'
    },

    waveDivider: {
        position: 'absolute',
        bottom: -5,
        left: 0,
        width: '127%',
        overflow: 'hidden',
        lineHeight: 0,
        zIndex: 0,
    },
    waveSvg: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    contactSection: {
        padding: '20px 10px',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    contactSectionheading: {
        fontSize: '2rem',
        color: '#0069C8',
        fontWeight: '500',
        marginBottom: '30px',
    },
    contactSectioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '1100px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    leftColumn: {
        flex: 1,
        backgroundColor: '#0069C8',
        color: '#fff',
        padding: '40px',
        textAlign: 'left',
    },
    rightColumn: {
        flex: 1,
        padding: '40px',
        textAlign: 'left',

    },
    divider: {
        border: '0',
        borderTop: '2px solid #fff',
        width: '50px',
        margin: '10px 0',
    },
    image: {
        height: 'auto',
        margin: '0 auto'
    },
    imgrightColumn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

export default Hero;
