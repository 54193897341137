import React, { useState } from 'react';
import './mobile.css';


const ContactUS = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company: "",
        position: "",
        contactNumber: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle the form submission logic here
        console.log("Form Data Submitted:", formData);
    };
    return (
        <div>
            <section>
                <div style={styles.herocontainer}>
                    <div style={styles.overlay}></div>
                    <h1 style={styles.herocontainerh1}>Contact Us</h1>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            Whether you have questions, feedback, or just want to connect, your inquiries are important. Reach out through the options below, and we'll respond promptly. Thank you for visiting — we're excited to hear from you!
                        </p>
                    </div>
                </div>

            </section>
            <div className='mobileform' style={styles.formContainer}>
                        <h1 style={styles.heading}>Send us a message</h1>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />

                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                style={styles.textarea}
                            />
                            <button type="submit" style={styles.button}>Submit</button>
                        </form>
                    </div>

            <section className="container">
              
                 <div style={styles.contactContainer}>
                    {/* Left Section - Contact Info */}
                    <div style={styles.contactInfo}>
                        <h3 style={styles.contactHeader}>Get In Touch</h3>
                        <hr style={styles.hr} />
                        <ul style={styles.contactList}>
                            <li style={styles.contactItem}>
                                <i className="fas fa-map-marker-alt" style={styles.icon}></i>
                                Unit 4A, Plot 54368, Gaborone
                            </li>
                            <li style={styles.contactItem}>
                                <i className="fas fa-phone-alt" style={styles.icon}></i>
                                +267 3165466
                            </li>
                            <li style={styles.contactItem}>
                                <i className="fas fa-envelope" style={styles.icon}></i>
                                info@agentbo.co.bw
                            </li>
                        </ul>
                        <h3 style={styles.contactHeader}>Find Us</h3>
                        <hr style={styles.hr} />
                        <div style={styles.mapContainer}>
                            {/* Placeholder for the Map */}
                            <iframe
                                title="AgentBo Map Location"
                                src="https://www.google.com/maps/dir//Unit+4A,+Plot+54368+Itowers,+Gaborone/@-24.6498306,25.8242521,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ebb5bffd0ba6413:0xddb7a82d196b66c5!2m2!1d25.9066537!2d-24.6498531?entry=ttu&g_ep=EgoyMDI0MTAyMS4xIKXMDSoASAFQAw%3D%3D"
                                width="100%"
                                height="200"
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                        <h3 style={styles.contactHeader}>Follow Us</h3>
                        <hr style={styles.hr} />
                        <div style={styles.socialIcons}>
                            <a href="#" style={styles.socialLink}>
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="#" style={styles.socialLink}>
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#" style={styles.socialLink}>
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>

                    {/* Right Section - Contact Form */}
                    <div className='heroimage' style={styles.formContainer}>
                        <h1 style={styles.heading}>Send us a message</h1>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />

                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                style={styles.textarea}
                            />
                            <button type="submit" style={styles.button}>Submit</button>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    );
};


const styles = {

    herocontainer: {
        backgroundImage: 'url("images/Group 171 (1).png")',
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: '0 auto',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4rem',
    },

    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 119, 209, 0.5)',  // Semi-transparent overlay
        zIndex: 1,  // Ensure this is below the h1
    },


    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#0069C8',
        fontWeight: '500'
    },

    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },


    contactContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap:100,
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,

    },
    contactInfo: {
        width: '100%',
        padding:'50px'
    },
    contactHeader: {
        fontSize: '24px',
        color: '#1a73e8',
    },
    hr: {
        border: '1px solid #1a73e8',
        marginBottom: '10px',
    },
    contactList: {
        listStyle: 'none',
        padding: '0',
    },
    contactItem: {
        marginBottom: '10px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '10px',
        color: '#1a73e8',
    },
    mapContainer: {
        marginBottom: '20px',
    },
    socialIcons: {
        display: 'flex',
        gap: '10px',
    },
    socialLink: {
        textDecoration: 'none',
        fontSize: '20px',
        color: '#1a73e8',
    },


    formContainer: {
        backgroundColor: "#fff",
        padding: "50px",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "90%",
        height: 'auto',
        // margin: "0 auto",
        textAlign: "center",
        boxSizing: "border-box",
    },

    form: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        boxSizing: "border-box",
    },
    textarea: {
        width: "100%",
        padding: "15px",
        marginBottom: "15px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "16px",
        resize: "vertical",
        boxSizing: "border-box",
    },
    button: {
        padding: "15px",
        backgroundColor: "#0066cc",
        color: "white",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",

    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        // margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },

};

export default ContactUS;
