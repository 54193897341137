import React from 'react';
import './mobile.css';
import { Link } from 'react-router-dom';

const Insurance = () => {
    return (
        <div>
            <section>
                <div style={styles.herocontainer}>
                    <div style={styles.overlay}></div>
                    <h1 style={styles.herocontainerh1}>Insurance</h1>
                </div>
            </section>

            <section style={styles.descriptionSection}>
                <div style={styles.descriptionContainer}>
                    <div style={styles.descriptionBox}>
                        <p>
                            Leverage agentBo to revolutionize your insurance services with a versatile 24/7 digital omni-channel approach. Engage clients effortlessly across WhatsApp, Instagram, Facebook, and beyond, ensuring convenient, direct communication anytime and anywhere.
                        </p>
                    </div>
                </div>

            </section>
            <section style={styles.section2}>
                <div  className="container" style={styles.container}>

                    <div  className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/insurance 1.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div  className="textColumn" style={styles.textColumn}>
                        
                    <div className="marginright">
                            <h1 style={styles.heading}>Enhance Insurance Services with Multi-Channel Engagement</h1>
                            <p style={styles.description}>
                                Enhance insurance services with personalized advice, efficient claims handling, and responsive customer support across multiple channels. Build trust and loyalty through seamless communication and quick responses.
                            </p>
                           
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>


                </div>

            </section>
            <div className="mobileimage" style={styles.imageColumn}>
                        <img
                            src="/images/insurance 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div className="textColumn" style={styles.textColumn}>
                        
                    <div style={{ marginRight: '20px', marginLeft: '60px'}}>
                            <h1 style={styles.heading}>Provide Instant Answers to Common Queries</h1>
                            <p style={styles.description}>
                                Imagine having a insurance expert available anytime. Our AI-driven assistant powered by GPT technology will instantly answer to your queries. No more waiting on hold—get the information you need, enhancing your experience and satisfaction.
                            </p>
                            <ul style={styles.ul}>
                                <li><p>Types of insurance policies offered </p></li>
                                <li><p>Ask for policy terms and conditions explanation </p></li>
                                <li><p>
                                    Ask how to process for filing a claim and more </p></li>
                            </ul>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage" style={styles.imageColumn}>
                        <img
                            src="/images/insurance 2.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>

            <section style={styles.section2}>
                <div className="container" style={styles.container}>
                    <div className="imageColumn" style={styles.imageColumn}>
                        <img
                            src="/images/insurance 3.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>

                    <div className="textColumn" style={styles.textColumn}>
                        
                    <div className="marginright">
                            <h1 style={styles.heading}>Increase Customer Support</h1>
                            <p style={styles.description}>
                                Experience the convenience of round-the-clock customer support. No matter the time of day, our bot is ready to assist you with any questions or concerns. Enjoy the peace of mind that comes with knowing help is always available, enhancing your satisfaction and trust in our services.
                            </p>
                            <ul style={styles.ul}>
                                <li><p>Reduce wait times with automated responses. </p></li>
                                <li><p>Offer 24/7 support availability. </p></li>
                                <li><p>Respond to customer inquiries instantly. </p></li>
                                <li><p>Streamline communication to resolve issues quickly. </p></li>
                            </ul>
                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>


                </div>

            </section>
            <div className="mobileimage"  style={styles.imageColumn}>
                        <img
                            src="/images/insurance 4.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
            <section style={styles.section2}>
                <div className="container"  style={styles.container}>



                    <div className="textColumn"  style={styles.textColumn}>
                        
                    <div style={{ marginRight: '20px', marginLeft: '60px'}}>
                            <h1 style={styles.heading}>Schedule Appointments and Improve registrations</h1>
                            <p style={styles.description}>
                                schedule appointments effortlessly. Whether you need a consultation, policy discussion, or assistance with a claim, booking time with an agent is as simple as sending a message.
                                <br />
                                <br />
                                Enjoy the convenience of seamless scheduling and easy access to professional advice, all within your favorite messaging app.
                            </p>

                            <button style={styles.ctaButton}><Link to="/Shedule-a-demo" style={{ textDecoration: 'none' ,color: 'inherit'}}>Schedule a demo</Link></button>
                        </div>
                    </div>

                    <div className="heroimage"  style={styles.imageColumn}>
                        <img
                            src="/images/insurance 4.png"
                            alt="Intro-image"
                            style={styles.heroImage}
                        />
                    </div>
                </div>

            </section>
        </div>
    );
};


const styles = {

    herocontainer: {
        backgroundImage: 'url("images/insurance cover.jpg")',
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

    },

    herocontainerh1: {
        position: 'relative',
        color: 'white',
        zIndex: 2,  // This keeps the text above the overlay
        fontSize: '4rem',
    },

    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 119, 209, 0.5)',  // Semi-transparent overlay
        zIndex: 1,  // Ensure this is below the h1
    },



    descriptionSection: {
        zIndex: 3,
    },

    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        padding: '25px',
        zIndex: 3,
    },

    descriptionBox: {
        backgroundColor: 'white',
        padding: '25px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '-100px',
        marginLeft: '10%',
        marginRight: '10%',
        zIndex: 3,

    },

    descriptionBoxP: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },

    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingTop: '0px',
        marginBottom: '50px',
        zIndex: 1,
    },
    herotextColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heroheading: {
        fontSize: '3rem',
        margin: '0 0 20px',
        color: '#fff',
    },
    smallheading: {
        fontSize: '1.3rem',
        color: '#fff'
    },
    herodescription: {
        fontSize: '1.1rem',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#fff',
    },
    textColumn: {
        flex: 1,
        // paddingRight: '80px',
        color: 'white',
    },
    heading: {
        fontSize: '2rem',
        margin: '0 0 20px',
        color: '#0069C8',
        fontWeight: '500'
    },

    description: {
        fontSize: '16px',
        marginBottom: '30px',
        lineHeight: '1.6',
        color: '#7a7a7a',
    },



    ctaButton: {
        padding: '15px',
        backgroundColor: 'transparent', // Transparent background
        color: '#0069C8', // Text color
        border: '2px solid #0069C8', // Optional border to outline the button
        borderRadius: '100px',
        cursor: 'pointer',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease', // Optional: smooth hover effect
    },
    ctaButtonHover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Light hover effect
    },
    imageColumn: {
        flex: 1,
        textAlign: 'right',
    },
    heroImage: {
        width: '90%',
        borderRadius: '30px',
    },

    waveDivider: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '127%',
        overflow: 'hidden',
        lineHeight: 0,
        zIndex: 0,
    },
    waveSvg: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    contactSection: {
        padding: '20px 10px',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    contactSectionheading: {
        fontSize: '2rem',
        color: '#0069C8',
        fontWeight: '500',
        marginBottom: '30px',
    },
    contactSectioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '1100px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    leftColumn: {
        flex: 1,
        backgroundColor: '#0069C8',
        color: '#fff',
        padding: '40px',
        textAlign: 'left',
    },
    rightColumn: {
        flex: 1,
        padding: '40px',
        textAlign: 'left',

    },
    divider: {
        border: '0',
        borderTop: '2px solid #fff',
        width: '50px',
        margin: '10px 0',
    },
    image: {
        height: 'auto',
        margin: '0 auto'
    },
    ul: {
        color: '#1a1a1a',
        marginBottom:'20px',
    }


};

export default Insurance;
